<script>
// import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Contacts-grid component
 */
export default {
  page: {
    title: "Banner List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      title: "Banner List",
      items: [
        {
          text: "Banner List",
          href: "#",
        },
        {
          text: "Banner",
          active: true,
        },
      ],
      banners: [],

      loadingfirst: true,
      statusArr: [
        { key: "true", value: "Active" },
        { key: "false", value: "InActive" },
      ],
      param: {
        type: "",
        temp_type: "",
        status: "",
      },
      isStatus: "true",
    };
  },
  mounted() {
    this.bannerpDetails();
  },
  methods: {
    async bannerpDetails() {
      this.loadingfirst = true;
      var result = await ApiClass.getRequest("admin/banner/get", true);
      // console.log(result);
      if (result.data.status_code == 1) {
        this.loadingfirst = false;
        this.banners = result.data.data;
      }
    },
    async bannerStatus(id, status) {
      var result = await ApiClass.updateRequest(
        "admin/banner/update/" + id + "/" + status,
        true
      );
      result.data.status_code == 1
        ? Swal.fire("Updated!", result.data.message, "success")
        : Swal.fire("Error!", result.data.message, "error");
    },
    async dlbanner(id, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          var response = await ApiClass.deleteRequest(
            "admin/banner/delete/" + id,
            true
          );
          response.data.status_code == 1
            ? this.banners.splice(index, 1)
              ? Swal.fire("Deleted!", response.data.message, "success")
              : ""
            : Swal.fire("Error!", response.data.message, "error");
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mt-3">
      <!-- No Result Found -->
      <div
        v-if="loadingfirst"
        class="d-flex justify-content-center align-items-center"
        style="height: 50vh"
      >
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div
        v-if="loadingfirst == false && banners.length == 0"
        class="d-flex justify-content-center align-items-center"
        style="height: 50vh"
      >
        <h1>No Result Found</h1>
      </div>

      <div
        v-for="(rec, index) in banners"
        :key="index"
        class="col-xl-3 col-sm-6"
      >
        <div class="card text-center" style="height: 262px">
          <div class="card-body" style="padding: 0; overflow: hidden">
            <img
              :src="rec.image"
              alt=""
              style="
                max-width: 380px;
                width: 100%;
                max-height: 210px;
                object-fit: cover;
                object-position: center;
              "
            />
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <template>
                  <div class="form-switch">
                    <input
                      v-model="rec.status"
                      class="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd"
                      @change="bannerStatus(rec.id, rec.status)"
                    />
                  </div>
                </template>
              </div>
              <div class="flex-fill">
                <a title="Delete" href="javascript: void(0);">
                  <i
                    class="mdi mdi-delete"
                    @click="dlbanner(rec.id, index)"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>